<template>
  <div>
    <v-card class="mx-auto">
        <div class="mb-2">
        <hb-notification
        v-if="onboardingData && onboardingData.propertyData.length == 0"
        type="success"
        notDismissable
        >
        Congratulations! Your merge has been completed. You can now view it in your Hummingbird production environment. <a class="hb-link" @click="routeToDashboard">Click here</a> to view your data
       </hb-notification>
      </div>
      <v-card-text v-if="onboardingData.company.length && onboardingData.propertyData.length"> 
         
        <div class="go-live-title text--primary ml-2">
          Your Go Live Date:          
            {{ onboardingData.propertyData[0].golive_date | formatDate }}
          
        </div>
        <div class="info-container mt-2 ml-2">
          <b>Technical Lead: </b>{{ onboardingData.company[0].tech_contact_name}}|
                {{ onboardingData.company[0].tech_contact_phone | formatPhone }} |
                {{ onboardingData.company[0].tech_contact_email }}
        </div>
      </v-card-text>
      <hr />
      <v-container class="mb-6">
        <v-row no-gutters>
          <v-col v-if="onboardingData.company.length && onboardingData.propertyData.length">
            <div >
              <div class="setup-msg">You are going to be setting up:</div>
              <div class="company-name" >
                {{ onboardingData.propertyData[0].name}}
              </div>
              <div class="company-address">
                {{ onboardingData.property.Address.address }},
                {{ onboardingData.property.Address.address2 }},
                {{ onboardingData.property.Address | formatAddress }}
              </div>
              <div
                class="get-started-btn-container" v-if="links['property']['status'] != 'locked'" >
                <hb-btn v-if="btnEnable" class="get-started-btn" @click="updateBtnStatus()">
                  Get Started
                </hb-btn>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="">
              <div class="container-note">
                <b>Note:</b> We will conduct QA testing in your live HB
                environment, including testing with real credit cards, to be
                sure everything is working as expected. You can either provide a
                credit card for this testing (and void / refund )
              </div>
              <br />
              <div class="container-note">
                <b>Note:</b> We will conduct QA testing in your live HB
                environment, including testing with real credit cards, to be
                sure everything is working as expected. You can either provide a
                credit card for this testing (and void / refund )
              </div>
              <br />
              <div class="container-note">
                <b>Note:</b> We will conduct QA testing in your live HB
                environment, including testing with real credit cards, to be
                sure everything is working as expected. You can either provide a
                credit card for this testing (and void / refund )
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import api from "../../../assets/api.js";
export default {
  data() {
    return {
      reveal: false,
      btnEnable:true
    };
  },
  computed: {
    ...mapGetters({
      propertyStatus: "onboardingTabsStore/getPropertySettingStatus",
      propertyTabs: "onboardingTabsStore/getPropertyTabs",
      links: "onboardingTabsStore/getOnboardingTabs",
      onboardingData: "onboardingTabsStore/getOnboardingData"
    })
  },
  filters: {
    formatDate(value) {
      if (!value) return null;
      return moment(value).format("MMMM DD YYYY");
    }
  },
  methods: {
    updateBtnStatus() {
      this.btnEnable = false;
      let url = `${api.MIGRATION}${this.onboardingData.property.id}/property-products`;
      api.post(this,url).then(r => {
        this.$store.dispatch("onboardingTabsStore/updateOnboardingTabStatus", {
          type: "mainmenu",
          screenname: "property",
          status: "complete"
        });
        this.$store.dispatch(
          "onboardingTabsStore/setActiveTab",
          this.propertyTabs["TaxRates"]
        );
        this.btnEnable  = true;
      }).catch(err=>{
        this.btnEnable  = true;
      })
    },
    routeToDashboard() {
      this.$store.dispatch("notificationsStore/setRemoveMergeNotification", {id: "undefined_Success" });
      this.$router.push('/dashboard');
    }
  }
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.go-live-title {
  font-size: 18px;
  color: black;
}
.info-container {
  font-size: 14px;
}
.container-note {
  font-size: 12px;
  color: #637381;
}
.get-started-btn {
  background: linear-gradient(180deg, #47c0bf -70%, #00848e 126.25%);
  color: white;
}
.setup-msg {
  font-size: 14px;
  font-weight: bold;
}
.company-name {
  margin-top: 15px;
  font-size: 20px;
  font-weight: bold;
}
.company-address {
  font-size: 14px;
  margin-top: 15px;
}
.get-started-btn-container {
  margin-top: 55px;
}</style
>>
